<template>
  <div>
    <strong>Nombre completo: {{ props.row.names }} {{ props.row.lastNames }}</strong><br>
    <b-tag>ID Operación: {{ props.row.operationGuid }}</b-tag>
    <br>
    <small>Fecha: {{ formatDate(props.row.createdAt) }}</small><br>
    <small>Documento de identidad: {{ props.row.documentId }}</small><br>
    <small>Nombres: {{ props.row.names }}</small><br>
    <small>Apellidos: {{ props.row.lastNames }}</small><br>
    <small>Ultima Operación: {{ props.row.lastEvent }}</small><br>
    <small>Coodigo respuesta ultima operación: {{ props.row.lastOperationCode }}</small><br>
    <small>Score configurado: {{ (props.row.scoreConfigured * 100).toFixed(2) }}%</small><br>
    <small>Score total: {{ (props.row.scoreTotal * 100).toFixed(2) }}%</small><br>
    <b-tag :type="getStatusTag(props.row.scoreConfigured, props.row.scoreTotal,props.row.status)">
      Estado: {{ getStatus(props.row.status) }}
    </b-tag>
  </div>
</template>

<script>
import {
  dateFormatting, getStatusTagSOP
} from "../../../assets/config/utilities";
import {STATES} from "../../../assets/config/constants/GeneralConstants";

export default {
  name: "FirstCardDetail",
  props: [
    'props'
  ],
  methods: {
    formatDate(date) {
      return dateFormatting(date);
    },
    getStatus(status) {
      return STATES[status].label
    },
    getStatusTag(scoreConfigured, scoreTotal, status) {
      return getStatusTagSOP(scoreConfigured, scoreTotal, status);
    },
  }
}
</script>

<style scoped>

</style>